import React from 'react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// interfaces
import { IImage } from '../../interfaces/image';
// components
import AddToBasket from '../add-to-basket';
import Image from '@components/image/image';
// styles
import styles from './hero-blood-test.module.scss';
import 'swiper/swiper.scss';
//! This is last Swiper instance on the page.
//! We need to add autoplay and loop props to swiper, test it if it woek and then remove this swiper too
//@ TODO: remove this swiper
// But it is not urgent as global styles are removed so we dont have conflicting styles anymore

interface IProps {
  title: string;
  description: string;
  image: IImage;
  phoneImages: any;
  plans: any;
}

SwiperCore.use([Autoplay, EffectFade]);

const HeroBloodTest: React.FC<IProps> = ({
  title,
  description,
  image,
  phoneImages,
  plans,
}: IProps) => (
  <section className={styles.section}>
    <div className={styles.imageHalf}>
      <div className={styles.desktopImage}>
        <Image filename={image.filename_disk} alt={image.title} width={850} />
      </div>
      <div className={styles.phoneWrapper}>
        <Swiper className={styles.bloodSwiper} effect="fade" autoplay={true} loop={true}>
          {phoneImages.map((image: any, index: number) => (
            <SwiperSlide key={index} className={styles.phoneImage}>
              {({ isActive }) => (
                <div className={isActive ? styles.activeSlide : styles.inactiveSlide}>
                  <Image filename={image.filename_disk} width={470} />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>

    <div className={styles.contentHalf}>
      <div className={styles.container}>
        <div className={styles.rowCentered}>
          <div className={styles.content}>
            <h1>{title}</h1>
            <hr className={styles.hrLeftAligned} />
            <p>{description}</p>
            {plans && plans.length > 0 && <AddToBasket plans={plans} />}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HeroBloodTest;
