import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, navigate, Link } from 'gatsby';
import { useLocation } from '@reach/router';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import HeroBloodTest from '../../components/hero-blood-test';
import HowItWorks from '../../components/how-it-works';
import ExpertApproval from '../../components/expert-approval';
import Reviews from '@components/reviews/reviews';
import Modal from '../../components/modal';
import ModalHeader from '../../components/modal/modal-header';
import ModalBody from '../../components/modal/modal-body';
import { MODAL_TYPE } from '@components/modal/constants';
import Image from '@components/image/image';
// services
import { trackProductViewed } from '../../services/tracking/tracking';
// styles
import styles from './blood.module.scss';
import AddToBasket from '../../components/add-to-basket';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
// constants
import { OUT_OF_STOCK } from '@constants/messages';

const BloodTest = () => {
  const content = useStaticQuery(graphql`
    query BloodTest {
      directusBloodTest {
        product {
          identifier
        }
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          title
          filename_disk
        }
        hero_phone_image_1 {
          title
          filename_disk
        }
        hero_phone_image_2 {
          title
          filename_disk
        }
        hero_phone_image_3 {
          title
          filename_disk
        }
        whats_tested_title
        whats_tested_items {
          title
          image_filename_disk
          reports
          bullets {
            title
            description
            icon_filename_disk
          }
        }
        experts_title
        experts_description
        experts {
          name
          role
          qualification
          image {
            title
            filename_disk
          }
        }
        hiw_title
        hiw_description
        hiw_items {
          title
          description
          filename_disk
        }
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        health_data_title
        health_data_description
        health_data_image {
          title
          filename_disk
        }
        section_last_title
        section_last_description
        section_last_button_label
        section_last_image {
          title
          filename_disk
        }
      }
    }
  `);

  const { directusBloodTest } = content;

  const {
    product,
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_image,
    hero_phone_image_1,
    hero_phone_image_2,
    hero_phone_image_3,
    whats_tested_title,
    whats_tested_items,
    experts_title,
    experts_description,
    experts,
    hiw_title,
    hiw_description,
    hiw_items,
    reviews_title,
    reviews_items,
    health_data_title,
    health_data_description,
    health_data_image,
    section_last_title,
    section_last_description,
    section_last_button_label,
    section_last_image,
  } = directusBloodTest;
  const { dataPlans: dataProduct, price: productPrice, isAvailable } = useGetProductPlansData(
    product.identifier
  );
  const [showModal, setShowModal] = useState(-1);

  const location = useLocation();

  // tracking
  useEffect(() => {
    trackProductViewed({
      name: 'Blood',
      url: location.href,
      product_id: product?.identifier,
      category: 'blood',
    });
  }, []);

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroBloodTest
        title={hero_title}
        description={hero_description}
        image={hero_image}
        phoneImages={[hero_phone_image_1, hero_phone_image_2, hero_phone_image_3]}
        plans={dataProduct?.product_plans ? dataProduct.product_plans : []}
      />

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{whats_tested_title}</h2>
            </div>
          </div>
          <div className={styles.tilesContainer}>
            {whats_tested_items.map((item: any, index: number) => (
              <div key={index} className={styles.tile}>
                <div onClick={() => setShowModal(index)}>
                  <Image filename={item.image_filename_disk} width={500} alt={item.title} />
                  <span className={styles.tileFooter}>
                    <p>{item.reports}</p>
                    <img src="/images/plus.png" alt="open popup" />
                  </span>
                </div>

                {showModal === index && (
                  <Modal handleClose={() => setShowModal(-1)} type={MODAL_TYPE.discover}>
                    <ModalHeader type={MODAL_TYPE.discover}>
                      <h4 className={styles.modalHeading}>{item.title}</h4>
                    </ModalHeader>
                    <ModalBody>
                      <ul className={styles.modalListWrapper}>
                        {item.bullets.map((bullet: any, index: number) => (
                          <li key={index} className={styles.modalBullet}>
                            <Image
                              filename={bullet.icon_filename_disk}
                              width={100}
                              alt={item.title}
                            />
                            <span>
                              <strong>{bullet.title}</strong>
                              {bullet.description}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </ModalBody>
                  </Modal>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <ExpertApproval
        title={experts_title}
        description={experts_description}
        experts={experts}
        backgroundColor="PrimaryLighter"
      />

      <HowItWorks title={hiw_title} description={hiw_description} items={hiw_items} />

      <section className={styles.sectionVideo}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <p className={styles.subheading}>{hiw_description}</p>

              <div className={styles.videoWrapper}>
                <iframe
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={false}
                  frameBorder="0"
                  src="https://www.youtube.com/embed/vf7c2Fq9k3M?rel=0"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Reviews title={reviews_title} items={reviews_items} showReview hideTrustPilot />

      <section className={styles.imageBottomSection}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentHalf}>
              <div>
                <Image src="/images/icon-data-security.svg" width={40} />
                <h2>{health_data_title}</h2>
                <hr />
                <p>{health_data_description}</p>
              </div>
            </div>
            <div className={styles.imageHalf}>
              <Image
                filename={health_data_image.filename_disk}
                alt={health_data_image.title}
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionLast}>
        <div className={styles.rowLast}>
          <div className={styles.image}>
            <Image filename={section_last_image.filename_disk} width={544} />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.contentLast}>
              <h2>{section_last_title}</h2>
              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html: section_last_description.replace('{price}', productPrice),
                }}
              />
              <AddToBasket
                showPlanLabel={false}
                plans={dataProduct.product_plans}
                isAvailable={isAvailable}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BloodTest;
